import {
  type JSX,
  type Component,
  Show,
  createSignal,
  createEffect,
  onCleanup,
} from "solid-js";
import { Img } from "~/components/image";
import { A } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { exclamationCircle } from "solid-heroicons/outline";
import blankCanvas from "~/assets/blankCanvas.png";

export const NotFound: Component<{ children?: JSX.Element }> = (props) => {
  const [isBlinking, setIsBlinking] = createSignal(false);

  createEffect(() => {
    const interval = setInterval(() => {
      setIsBlinking(true);

      setTimeout(() => {
        setIsBlinking(false);
      }, 1000);
    }, 4500); 
    onCleanup(() => clearInterval(interval));
  });

  return (
    <div class="px-2">
      <div class="grid sm:grid-cols-2 my-6 md:my-10  mx-auto max-w-6xl">
        <div class=" flex justify-center md:justify-end">
          <Img src={blankCanvas} layout="fullWidth" class="max-w-lg" />
        </div>
        <div class="flex max-med:justify-center">
          <Show
            when={props.children}
            fallback={
              <>
                <div class="p-6 flex flex-col justify-center  h-full gap-3 md:gap-5">
                  <div class="font-mono bg-red-50 border px-3 py-2 rounded-md text-xl md:text-2xl flex items-center">
                    <Icon
                      path={exclamationCircle}
                      class="w-6 h-6 md:w-8 md:h-8 text-roma-dark-grey animate-pulse mr-2"
                    />
                    <p>
                      ERROR: 404{" "}
                      <span class="relative inline-block">
                        {isBlinking() ? "¦" : ":"}
                        {"("}
                        <span class="absolute top-0 right-2.5 text-sm text-roma-blue">
                          {isBlinking() ? "*" : ""}
                        </span>
                      </span>
                    </p>
                  </div>
                  <h3 class="text-2xl lg:text-3xl font-bold">
                    Uh-Oh...There's nothing here
                  </h3>
                  <p>
                    We couldn't find the page you were looking for, or it
                    doesn't exist!
                  </p>
                  <p>
                    Take me{" "}
                    <A href="/" class="text-roma-blue">
                      Home
                    </A>
                    !
                  </p>
                </div>
              </>
            }
          >
            {props.children}
          </Show>
        </div>
      </div>
    </div>
  );
};
